import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import { BrowserRouter } from 'react-router-dom';
import { AppApolloProvider } from './AppApolloProvider';
import { AppAuthProvider } from './AppAuthProvider';

const colors = {
  brand: {
    900: '#1a365d',
    800: '#153e75',
    700: '#2a69ac',
  },
};

const theme = extendTheme({ colors });

export const AppProviders: React.FC = ({ children }) => {
  return (
    <ChakraProvider theme={theme}>
      <BrowserRouter>
        <AppAuthProvider>
          <AppApolloProvider>{children}</AppApolloProvider>
        </AppAuthProvider>
      </BrowserRouter>
    </ChakraProvider>
  );
};
