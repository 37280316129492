import React from 'react';
import {
  Link,
  HStack,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  Box,
  Container,
  InputGroup,
  InputLeftElement,
  Input,
  Button,
} from '@chakra-ui/react';
import { ColorModeSwitcher } from './ColorModeSwitcher';
import { Route, Routes, Link as RouterLink } from 'react-router-dom';
import { FaSignOutAlt, FaUserAlt } from 'react-icons/fa';
import { ImProfile, ImSearch } from 'react-icons/im';

import SampedTextLogo from './samped-text-logo.svg';
import { useRootAuth } from '../../../AppAuthProvider';
import { TodosComponent } from '../../../TodosComponent';

export const AppLayout: React.FC = ({ children }) => {
  const auth = useRootAuth();
  return (
    <>
      <Box p={3}>
        <HStack spacing={3} justifyContent="space-between">
          <div>
            <RouterLink to="/">
              <img src={SampedTextLogo} alt="Samped" style={{ marginLeft: 5, height: 38 }} />
            </RouterLink>
          </div>

          <Container maxWidth="container.xl">
            <Link as={RouterLink} to="/" style={{ padding: 5 }}>
              Home
            </Link>
            <Link as={RouterLink} to="/users" style={{ padding: 5 }}>
              Users
            </Link>
          </Container>

          <HStack spacing={3}>
            <InputGroup>
              <InputLeftElement pointerEvents="none" children={<ImSearch color="gray.300" />} />
              <Input type="tel" placeholder="Search" />
            </InputGroup>

            {auth.state === 'authenticated' ? (
              <Menu>
                <MenuButton as={IconButton} aria-label="Settings" icon={<FaUserAlt />} variant="outline" />

                <MenuList>
                  <MenuItem icon={<ImProfile />}>Edit Profile</MenuItem>
                  <MenuItem icon={<FaSignOutAlt />} onClick={() => auth.client.logout()}>
                    Log Out
                  </MenuItem>
                </MenuList>
              </Menu>
            ) : (
              <Button colorScheme="blue" onClick={() => auth.client?.loginWithRedirect()}>
                Sign In
              </Button>
            )}

            <ColorModeSwitcher justifySelf="flex-end" />
          </HStack>
        </HStack>
      </Box>

      <Routes>
        <Route path="/" element={<Container maxWidth="container.xl">Home</Container>} />
        <Route
          path="users"
          element={
            <Container maxWidth="container.xl">
              Users <TodosComponent />
            </Container>
          }
        />
      </Routes>
    </>
  );
};
