import React from 'react';
import createAuth0Client, { Auth0Client } from '@auth0/auth0-spa-js';
import { useNavigate } from 'react-router-dom';

const AUTH0_CONFIG = {
  domain: 'samped.us.auth0.com',
  client_id: '5gl169qHL1owwBGyyKsUddy56HpZN0S7',
  audience: 'https://samped.us.auth0.com/api/v2/',
};

export type UserType = any;

export type AppAuth =
  | { state: 'init'; client: null }
  | { state: 'unauthenticated'; client: Auth0Client }
  | {
      state: 'authenticated';
      client: Auth0Client;
      user: UserType;
      token: string;
    }
  | { state: 'error'; client: Auth0Client };

export const AppAuthState = React.createContext<null | AppAuth /* (AppAuth & { setOrg: (org: any) => any }) */>(null);

/**
 * The `AppAuth` type is union type which could throw typescript errors.
 * This hook should be used when first loading the app or at very low level components.
 *
 * @returns
 */
export const useRootAuth = () => {
  const context = React.useContext(AppAuthState);

  if (!context) {
    throw new Error('`useRootAuth` must be used within `AppAuthProvider`');
  }

  return context;
};

/**
 * This hook returns the discriminated `AppAuth` in the `authenticated` state.
 * It should only be used in components that require authentication.
 *
 * @returns
 */
export const useAuth = () => {
  const context = useRootAuth();
  if (context.state !== 'authenticated') {
    throw new Error('`useAuth` must only be used in components that guarantee user has been authenticated.');
  }
  return context;
};

export const AppAuthProvider: React.FC = (props) => {
  const navigate = useNavigate();
  const [context, setContext] = React.useState<AppAuth>({ state: 'init', client: null });

  // on mount, fetch everything
  React.useEffect(() => {
    const initAuth0 = async () => {
      const client = await createAuth0Client({
        ...AUTH0_CONFIG,
        redirect_uri: window.location.origin,
      });

      try {
        // code query string param `code=` can only allow handle redirect one time
        // must handle it before continuing, must remove it immediately from the url
        if (window.location.search.includes('code=')) {
          const { appState } = await client.handleRedirectCallback();

          // return to `targetUrl` if defined, otherwise home page
          navigate(appState?.targetUrl || '/', { replace: true });
        }

        const isAuthenticated = await client.isAuthenticated();
        if (!isAuthenticated) {
          setContext({ state: 'unauthenticated', client });
          return;
        }

        const user = await client.getUser();

        const token = await client.getTokenSilently();

        // TODO axios header and/or apollo client token stuff

        // TODO bootstrap user

        // TODO datadog logs and rum context

        setContext({
          state: 'authenticated',
          client,
          user,
          token,
        });
      } catch (e) {
        setContext({ state: 'error', client });
      }
    };

    initAuth0();
    // eslint-disable-next-line
  }, []);

  return <AppAuthState.Provider value={context} {...props} />;
};
