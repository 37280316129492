import { AppProviders } from './AppProviders';
import { AppLayout } from './modules/core/layout/AppLayout';

export const AppRoot: React.FC = ({ children }) => {
  return (
    <AppProviders>
      <AppLayout />
    </AppProviders>
  );
};
