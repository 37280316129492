import { ApolloClient, ApolloProvider, InMemoryCache, HttpLink, HttpOptions } from '@apollo/client';
// import { setContext } from '@apollo/client/link/context';
import { useRootAuth } from './AppAuthProvider';

const createApolloClient = (token?: string) => {
  const headers: HttpOptions['headers'] = {};

  if (token) {
    headers['Authorization'] = `Bearer ${token}`;
  }

  return new ApolloClient({
    link: new HttpLink({
      uri: 'https://national-monkey-93.hasura.app/v1/graphql',
      headers,
    }),
    cache: new InMemoryCache(),
  });
};

export const AppApolloProvider: React.FC = ({ children }) => {
  const auth = useRootAuth();
  const client = createApolloClient((auth as any).token || undefined);
  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};
