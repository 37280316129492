import { gql, useQuery } from '@apollo/client';

const GET_MY_TODOS = gql`
  query getMyTodos {
    todos(where: { is_public: { _eq: false } }, order_by: { created_at: desc }) {
      id
      title
      created_at
      is_completed
    }
  }
`;

export const TodosComponent = () => {
  const query = useQuery(GET_MY_TODOS);

  if (query.loading) {
    return <>Loading...</>;
  }

  if (query.error) {
    return <>Error!</>;
  }

  return (
    <>
      <pre>{JSON.stringify(query.data, null, 2)}</pre>
    </>
  );
};
